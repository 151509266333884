import React from 'react'

import Wrapper from '../components/Wrapper'

const Contact = () => {

  return (
    <Wrapper>
      <div className="flex flex-col text-lg xs:text-xl h-full">
        <p className="flex flex-col flex-grow h-[75vh] items-center justify-center m-0">
          <p className="text-yellow-500">I'm looking forward to hearing from you. :) </p>
          <p>Please contact me at</p>
          <p className="font-bold"><a href="mailto:hello@nicolemedvecka.com">hello@nicolemedvecka.com</a></p>
        </p>
        <p className="text-yellowstone text-xs sm:text-sm px-2">
          This website is copyright protected. All rights reserved. All copyright, other intellectual property and information on this website is property of Nicole Medvecka. No part of this website may be copied, adapted, modified, reproduced or transmitted in any form.
        </p>
      </div>
    </Wrapper>
  )
}

export default Contact