import React, { useState } from 'react'
import { Sling as Hamburger } from 'hamburger-react'
import { Link } from 'gatsby'

import { RiVimeoLine, RiInstagramLine, RiLinkedinLine } from 'react-icons/ri'

import Menu from './Menu'
import SEO from './SEO/'
import DarkModeToggler from './DarkModeToggler'

export default function Wrapper({ children }) {

  if (typeof window !== 'undefined') {
    // From tailwindcss docs: https://tailwindcss.com/docs/dark-mode
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    // Whenever the user explicitly chooses light mode
    localStorage.theme = 'light'

    // Whenever the user explicitly chooses dark mode
    localStorage.theme = 'dark'

    // Whenever the user explicitly chooses to respect the OS preference
    localStorage.removeItem('theme')
  }
  
  const [isOpen, setOpen] = useState(false)

  return (
    <main className="font-brand font-thin min-h-screen text-yellowstone theme-dark bg-primary text-text-main">
      <SEO />
      <title>Nicole Medvecka</title>
      <div className="flex flex-col text-center">
        <div>
          <div className="relative max-w-7xl mx-auto py-8 px-4 sm:py-10 sm:px-6 lg:px-8 z-20">
            <h1 className="text-center text-lg tracking-widest text-yellowstone xs:text-lg lg:text-xl">
              <Link to="/" className="xs:hidden flex flex-col"><span>NICOLE MEDVECKA </span><span>CINEMATOGRAPHER</span></Link>
              <Link to="/" className="hidden xs:block"><span>NICOLE MEDVECKA . CINEMATOGRAPHER</span></Link>
            </h1>
          </div>

          <div className="flex items-center fixed top-6 right-4 xl:right-4 z-20">
            <DarkModeToggler />
            <Hamburger toggled={isOpen} toggle={setOpen} color="#716f6a" distance="lg" size={32} label="Show menu" />
          </div>
        </div>

        <div className="flex-grow">
          {children}
        </div>
      
        <div className="mx-auto px-0 my-10 text-yellowstone flex z-20 pb-4 xs:pb-10 mobile:pb-0 lg:mb-1">
          <span className="text-center mx-auto flex flex-row items-center space-x-4">
            <a href="https://vimeo.com/nicole8mn" target="_blank" rel="noreferrer"><RiVimeoLine size="1.5em" /></a>
            <a href="https://instagram.com/nicole_8mn" target="_blank" rel="noreferrer"><RiInstagramLine size="1.5em" /></a>
            <a href="https://www.linkedin.com/in/nicole-medvecka-519a26258" target="_blank" rel="noreferrer"><RiLinkedinLine size="1.5em" /></a>
          </span>
        </div>
        {isOpen && <Menu />}
      </div>
    </main>
  )
}